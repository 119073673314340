.darkbgHome {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 30%, rgba(57, 57, 57, 1) 100%);
    padding-bottom: 30px;

    .landingbanner {
        color: #fff;
        min-height: 500px;
        margin: 0 auto;
        text-align: center;
        div{
            margin: 0px 0px 100px;;

            h2{
                font-size: 25px;
                font-weight: 500;
                font-family: rubik;
                line-height: 30px;
                margin: 20px auto 0;
                max-width: 700px;
            }
            p{
                font-size: 20px !important;
                line-height: 24px;
                font-weight: 300;
                font-family: rubik;
                margin: 10px auto 0;
                max-width: 700px;
                color: #fff;
                text-align: center;
            }
        }
        .hometopvideo{
          position: relative;

          .volumebtn{
            position: absolute;
            top: 24px;
            right: 16px;
            opacity: 0.8;
            border: 1px solid rgba(255,255,255, 0.8);
            padding: 8px;
            border-radius: 10px;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;           

            svg{
            font-size: 22px;
            }
          }
        
        }
        video{width: 100%; min-height: 550px;}

        .action-box{
            display: flex;
            max-width: 900px;
            margin: 0 auto;
            justify-content: space-between;
        }
    }
    .opencallstrip{
      margin: 0 auto 100px;
      background-size: cover;
      text-align: center;
      position: relative;
      min-height: 240px;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .opencallinnrstrip{
          z-index: 1;
          a{
              text-decoration: none;
          }
      }
      p{
        font-size: 34px !important;
        font-weight: 500;
        font-family: rubik;
        line-height: 47px;
        margin-top: 0;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 0;           
      }
  }
    .container-fluid{padding: 0;}
    .actiondemotext{
        position: relative;

        .top-action{
            position: absolute;
            top: 28%;
            left: 50%;
            transform: translate(-50%, -70%);
            font-size: 18px !important;
            line-height: 24px;
            font-weight: 500;
            font-family: 'Rubik';
        }
        .bottom-action{
            position: absolute;
            bottom: 18%;
            left: 50%;
            transform: translate(-50%, 0%);
            font-size: 18px !important;
            line-height: 24px;
            font-weight: 500;
            font-family: 'Rubik';
        }
    }
    .middleStrip{
        text-align: center;
        padding: 30px 0;
        border-top: 2px solid #8B0A8C ;
        border-bottom: 2px solid #8B0A8C ;

        p{
            font-size: 50px !important;
            line-height: 62px;
            font-weight: 500;
            font-family: 'Rubik';
            margin-bottom: 0;        
        }
    }
    .slidermianCollection1{
        padding: 0 12px;
        ul.react-multi-carousel-track {
            padding-bottom: 220px;
        }
        .slider-text{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 130px;
          margin-top: 20px;
       
        .slideData{
            margin-top: 16px;
            font-size: 20px !important;
            line-height: 24px;
            font-weight: 400;
            font-family: 'Rubik';
            margin: 0;;
        }
      }
        a{
          text-decoration: none;
          width: 100%;
          height: 100%;
        }
        .react-multiple-carousel__arrow{display: none;}
    }

    .lowerbanner{
        color: #fff;
        margin: 0 auto;
        text-align: center;
        margin-top: 100px;

        .homemainlisitng{
            display: flex;
            justify-content: space-between;
            margin: 100px 0;
            
            .homelistrightside{
                margin:30px 100px 0;
                text-align: left;

                h2{
                    font-size: 40px !important;
                    line-height: 47px;
                    margin-bottom: 60px;
                    font-family: 'Rubik';
                    font-weight: 400;
                }
                p{
                    font-size: 20px !important;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: 'Rubik';
                    text-align: left !important;
                }
            }
        }
        .list-group-item {
            flex: 0 23%;
            margin: 0 10px;
            background: #363636;
            border-radius: 10px !important;
            min-height: 300px;
            border: none;
        }
        h3{
            font-size: 40px;
            font-weight: 47px;
            font-family: rubik;
            font-weight: 400;
            color: #fff;}
    }

    .landingpage_width {
        max-width: 1100px;
        margin: 0px auto;
    }
}
  
  .react-multi-carousel-list {
    padding-top: 80px;
    margin-top : 20px;
  }
  
  .react-multiple-carousel__arrow--left {
    left : auto !important;
    right: 90px;
  }
  
  .react-multiple-carousel__arrow {
    position        : absolute;
    top             : 20px;
    background-color: #ffe0c3 !important;
    color           : #24262b !important;
    min-width       : 36px !important;
    min-height      : 36px !important;
  }
  
  .react-multiple-carousel__arrow::before {
    color: #24262b !important;
  }
  
  .react-multiple-carousel__arrow:hover {
    background: #ffa34e !important;
  }
  
  p.hearthonslider {
    position : absolute;
    right    : 6px;
    top      : 5px;
    font-size: 18px !important;
    color    : #24262b;
    cursor   : pointer;
  }
  
  .sliderdemotext {
    height: 100%;
  
    svg.xyz {
      color: #ff7b54;
    }
  }
  
  .sliderdemotext img {
    object-fit: cover;
  }
.image-item{
    width: 262px !important;
    margin: 10px;
    height: 310px;
    img{
        object-fit: cover;
        padding: 0 !important;
    }
}
.headingCollectionSlider {
  display    : flex;
  align-items: center;
  position   : absolute;

  h1 {
    font-size   : 70px;
    line-height : 78px;
    font-family : Open Sans;
    display     : inline-block;
    margin-right: 25px;
    font-weight : 300;
    color       : #fff;
  }

  span {
    a {
      width       : 42px;
      display     : inline-block;
      margin-right: 15px;

      img {
        width: inherit;
      }
    }

  }
}
@media screen and (min-width: 1800px){
    .home--list .CollectionList_main .CollectionImage {
        min-height: 277px;
    }
}
@media screen and (min-width: 1600px){
  .darkbgHome .opencallstrip{
    min-height: 260px;
  }
}

.react-multiple-carousel__arrow--right {
  right: 10px !important;
}

.react-multiple-carousel__arrow--left {
  right: 60px !important;
}

@media screen and (min-width: 360px) and (max-width: 400px){
  .collection-title{
    font-size: 17px !important;
    line-height: 20px !important;
    top: 15px;
    left: -80px !important;
    width: 14px !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 359px){
  .collection-title{
    font-size: 14px !important;
    line-height: 18px !important;
    top: 15px;
    left: -70px !important;
    width: 12px !important;
  }
}
@media screen and (min-width: 401px) and (max-width: 576px){
  .collection-title{
    font-size: 18px !important;
    line-height: 22px !important;
    top: 35px;   
    width: 14px !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 991px){
  .collection-title{
    font-size: 22px !important;
    line-height: 30px !important;
    top: 0px;   
    width: 14px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px){
  .collection-title{
    width: 20px;
    font-size: 24px;
    position: relative;
    right: -10px;
    top: 0px;
    line-height: 27px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1400px){
  .collection-title{
    width: 20px;
    font-size: 24px;
    position: relative;
    right: -10px;
    top: 0px;
    line-height: 27px;
  }
}

// Responsinve CSS Start
$small: 300px;
$medium: 900px;
  @media screen and (min-width: $small) and (max-width: $medium) {
    
    .landingbanner {
        min-height: 300px;
    }   
    .darkbgHome .landingbanner div {
        margin: 40px 0;
    }
    .darkbgHome .landingbanner .action-box{display: block;}
    .darkbgHome .actiondemotext .bottom-action {
        bottom: 26%;
        width: 24%;
    }
    .headingCollectionSlider {
        top: -27px;
      }
      .opencallstrip{
        background-position-x: center;
      }
      .headingCollectionSlider h1 {
        font-size   : 24px;
        margin-right: 10px;
      }
    
      .react-multi-carousel-list {
        padding-top: 30px;
      }
      .darkbgHome {
        background: linear-gradient(180deg, black 50%, #393939 100%);
    }
      .react-multiple-carousel__arrow {
        top       : 0px;
        min-width : 24px !important;
        min-height: 24px !important;
      }
    
      .react-multiple-carousel__arrow::before {
        font-size: 14px !important;
      }
    
      .react-multiple-carousel__arrow--left {
        right: 45px !important;
      }
    
      .react-multiple-carousel__arrow--right {
        right: 10px !important;
      }
    
      .headingCollectionSlider span a {
        width       : 35px;
        margin-right: 10px;
      }
      .headingCollectionSlider {
        top: -27px;
      }
    
      .headingCollectionSlider h1 {
        font-size   : 24px;
        margin-right: 10px;
      }
    
      .react-multi-carousel-list {
        padding-top: 30px;
      }
    
      .react-multiple-carousel__arrow {
        top       : 0px;
        min-width : 24px !important;
        min-height: 24px !important;
      }
    
      .react-multiple-carousel__arrow::before {
        font-size: 14px !important;
      }
    
      .react-multiple-carousel__arrow--left {
        right: 45px !important;
      }
    
      .react-multiple-carousel__arrow--right {
        right: 10px !important;
      }
    
      .headingCollectionSlider span a {
        width       : 35px;
        margin-right: 10px;
      }
    
}
@media screen and (min-width: 767px) and (max-width: 991px) {
    .darkbgHome .lowerbanner .homemainlisitng .homelistrightside{
        margin: 30px 50px 0;
    }
    .darkbgHome .lowerbanner .list-group-item{
        min-height: 220px;
    }
}
@media screen and (max-width: 767px){
    .darkbgHome .landingbanner video {
        min-height: initial;
    }  
    .darkbgHome .lowerbanner .homemainlisitng .homelistrightside h2 {
            font-size: 36px !important;
            line-height: 42px;
            margin-bottom: 30px;    
    }
    .darkbgHome .lowerbanner .homemainlisitng .homelistrightside {
        margin: 30px 0px 0 50px;
        text-align: left;
    }
}
@media screen and (max-width: 576px){
    .darkbgHome .actiondemotext .bottom-action {
        bottom: 21%;
    }
    .darkbgHome .lowerbanner .homemainlisitng {
        display: block;
        margin: 50px 0;
    }
    .darkbgHome .lowerbanner .homemainlisitng .homelistrightside {
        margin: 30px 0px 0;
        text-align: center;
    }
    .darkbgHome .lowerbanner .homemainlisitng .homelistrightside h2{
        margin-bottom: 30px;
    }
    .list-group-horizontal {
        flex-direction: column !important;
    }
    .darkbgHome .lowerbanner .list-group-item{
        margin: 20px 0px;
    }
    .darkbgHome .middleStrip p {
        font-size: 40px !important;
        line-height: 50px;
    }
}



// Responsive CSS end 