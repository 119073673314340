.darkcontactBlack {
    background    : rgb(228, 178, 178);
    background    : linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(57, 57, 57, 1) 100%);
    padding-bottom: 60px;

    .contactdesc {
        font-size    : 30px;
        line-height  : 42px;
        color        : #fff;
        text-align   : center;
        margin-bottom: 40px;
        font-weight  : 500;
        font-family  : 'Rubik';
        padding      : 0;
        margin-bottom: 100px;
    }

    .contactMain {
        margin       : 0 auto !important;
        margin-top   : 100px !important;
        margin-bottom: 50px !important;

        .form-label {
            font-size  : 16px;
            line-height: 24px;
            color      : #fff;
            font-family: Muli;
            font-weight: 500;
        }

        input {
            border-radius: 8px;
            background: #F0F4F8;
            height: 60px;
            margin-bottom: 20px;
            font-size: 16px;
            color: #A6BCD0;
            letter-spacing: -0.16px;
            line-height: 22px;
            padding-left: 16px;
            font-family: Acumin Pro SemiCondensed;
        }
        textarea{
            border-radius: 8px;
            background: #F0F4F8;
            margin-bottom: 20px;
            font-size: 16px;
            color: #A6BCD0;
            letter-spacing: -0.16px;
            line-height: 22px;
            padding-left: 16px;
            padding-top: 16px;
            font-family: Acumin Pro SemiCondensed;
        }
        
        .form-control::placeholder {
            color    : #91959C;
            font-size: 16px;
        }

        .col-lg-5 {
            width   : 420px;
            margin  : 0 auto;
            display : table;
            position: relative;

            &::before {
                content            : "";
                width              : 364px;
                height             : 364px;
                background-image   : url(../../static/img/contactbg.svg);
                background-position: left top;
                background-repeat  : no-repeat;
                background-size    : cover;
                position           : absolute;
                top                : -50px;
                left               : -50px;
            }
        }
        textarea::placeholder,
        input::placeholder{
            font-size: 16px;
            font-weight: 400;
            color: #A6BCD0;
            letter-spacing: -0.16px;
            line-height: 22px;
            font-family: Acumin Pro SemiCondensed;
        }

        form {
            div {
                margin-bottom: 15px;
                position     : relative;
            }

            textarea {
                height: 132px;
            }

            span {
                position   : absolute;
                right      : 0;
                font-size  : 14px;
                color      : #fff;
                line-height: 24px;
                font-family: Muli;
            }
        }

        .smallBtn {
            font-size    : 14px;
            line-height  : 18px;
            border-radius: 28px;
            background   : #681f9d !important;
            border-color : #681f9d !important;
            width        : 160px;
            height       : 40px;
            margin-top   : 20px;
            font-family  : Muli;
            font-weight  : bold;
        }

    }
}

@media screen and (max-width: 991px) {
    .contactMain {
        width        : 100%;
        padding      : 0 15px;
        margin-top   : 50px;
        margin-bottom: 30px;
    }

    .contactdesc {
        font-size  : 25px;
        line-height: 35px;
    }
    .darkcontactBlack .contactMain .col-lg-5::before{display: none;}
    .darkcontactBlack .contactdesc{margin-bottom: 50px;}
}

@media screen and (max-width: 768px) {
    .contactMain {
        width        : 100%;
        padding      : 0 0;
        margin-top   : 50px;
        margin-bottom: 30px;
    }

    .contactdesc {
        font-size  : 20px;
        line-height: 30px;
    }
}