.darkbgterms{
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(57,57,57,1) 100%); 

.termsMain {
  padding-top: 100px;
  .topbannersection{
        z-index: 9;
    img {
      width: 100%;
      margin: 0 auto;
    }
  }
  .col-lg-9{margin:0 auto}

}

.termsInner{
  font-size: 23px;
  line-height: 28px;
  font-weight: 400;
  font-family: Rubik;
  text-align: center;
  color: #fff;
  .termslist {
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
    position: relative;    
    padding: 30px;
    }
    h3 {
      margin-bottom: 30px;
      font-size: 23px;
      line-height: 28px;
      font-weight: 400;
    }
    span {
      margin-bottom: 30px;
      display: inline-block;
      width: 100%;
    }
  }
  .termOverlay {
  position: relative;
  margin-top: -341px;
    img {
      width: 100%;
  }
}

.termsTop {
  max-width: 1296px;
  position: relative; 
  img{
    width: 100%;
  }
  .termstitle{    
    font-size: 30px;
    font-family: rubik;
    line-height: 42px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
  }
}
}
@media only screen and (min-width: 768px) and (max-width: 991px){

  .TermsofUsepage .termsInner .termslist{
    padding: 0 60px;
    padding-top: 60px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px){
  .TermsofUsepage .termsMain{
    padding-top: 60px;
  }
  .TermsofUsepage .termsTop img{
    display: none;
  } 
 
  .TermsofUsepage .termsInner .termslist{
    padding:  30px;
    margin-bottom: 0;
  }
  .TermsofUsepage .termsTop .termstitle {
    transform: none;
    font-size: 30px;
    line-height: 42px;
    width: auto;
    margin-bottom: 0;
    padding-top: 20px;
  }
  .TermsofUsepage .termsInner h3{
    margin-bottom: 30px;
  }
}