.faq{

    .inviteform{
        h2{text-transform: uppercase;}
    }background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 100%);

    .faqdetails{
        padding: 200px 100px 100px 100px; 
        .faqtitle{
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
            font-family: Rubik;
            margin-bottom: 10px;
            color: #fff;
        }
        .faqtext{
            font-size: 16px !important;
            line-height: 24px;
            font-weight: 500;
            font-family: Rubik;
            margin-bottom: 30px;
            color: #fff;
        }
    }
}

@media screen and (max-width: 900px) {
    .faq .faqdetails{
        padding: 50px 0px ; 
    }
}
@media screen and (max-width: 567px) {
    .faq .faqdetails{
        padding: 0px 0px ; 
    }
}
    