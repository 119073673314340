.darkbgcollectorrights {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(57, 57, 57, 1) 100%);

    .innercollectbg {
        position: relative;

        &::before {
            content            : "";
            width              : 400px;
            height             : 310px;
            background-image   : url(../../static/img/whitelistbg1.svg);
            background-position: left top;
            background-repeat  : no-repeat;
            background-size    : cover;
            position           : absolute;
            bottom             : -10px;
            left               : 30px;
        }

        &::after {
            content            : "";
            width              : 400px;
            height             : 310px;
            background-image   : url(../../static/img/whitelistbg2.svg);
            background-position: left top;
            background-repeat  : no-repeat;
            background-size    : cover;
            position           : absolute;
            top                : 30px;
            right              : 30px;
        }

        .center-container {
            margin  : 150px auto;
            position: relative;

            .inviteform {
                text-align    : center;
                padding       : 0 60px;
                z-index       : 9;
                position      : relative;
                padding-bottom: 120px;

                h2 {
                    font-size  : 70px;
                    font-weight: 500;
                    line-height: 82px;
                    font-family: rubik;
                    color      : #fff;
                    margin-top : 47px;
                }
            }
        }
    }

    .artistsrights {
        margin-top: 100px;
        padding-bottom: 100px;
        
        h3{
            font-size: 35px;
            font-weight: bold;
            line-height: 42px;
            font-family: rubik;
            margin-bottom: 40px;
            color: #fff;
        }
        p{
            font-size: 20px !important;
            font-weight: 400;
            line-height: 24px;
            font-family: rubik;
            margin-bottom: 20px;
        }              
    }
    .bottomtext{
        text-align: center;
        padding-bottom: 100px;
        p{
            font-size: 15px !important;
            font-weight: 500;
            line-height: 18px;
            font-family: rubik;
        }
    }
    .pink-color{color:#FF0098; font-weight: 500;} 
    .pink-color1{color:#BB1C7B; font-weight: 500; text-decoration: none;}  
    .leftside{padding-right: 50px;}
    .rightside{padding-left: 50px;}
}

@media screen and (min-width: 900px) and (max-width: 1100px) {

    .darkbgcollectorrights .innercollectbg::before,
    .darkbgcollectorrights .innercollectbg::after {
        width : 330px;
        height: 260px;
    }

    .darkbgcollectorrights .innercollectbg .center-container .inviteform {
        padding       : 0 0 0 60px;
        padding-bottom: 200px
    }
}

@media screen and (max-width: 900px) {

    .darkbgcollectorrights .innercollectbg::before,
    .darkbgcollectorrights .innercollectbg::after {
        display: none;
    }

    .darkbgcollectorrights .innercollectbg .center-container .inviteform {
        padding-bottom: 100px
    }
    .darkbgcollectorrights .innercollectbg .center-container {
        margin: 150px auto 50px;
    }
    .darkbgcollectorrights .leftside{
        padding-right: 0;
    }
    .darkbgcollectorrights .rightside{
        padding-left: 0;
    }
    .darkbgcollectorrights .artistsrights {
        margin-top: 50px;
    }
}

@media screen and (max-width: 767px) {
    .darkbgcollectorrights .innercollectbg .center-container {
        margin: 100px auto 50px;
    }
    .darkbgcollectorrights .innercollectbg .center-container::before {
        display: none;
    }

    .darkbgcollectorrights .innercollectbg .center-container .inviteform {
        padding: 0;
    }
}

@media screen and (max-width: 576px) {
    .darkbgcollectorrights .innercollectbg .center-container .inviteform h2 {
        font-size: 40px;
        line-height: 53px;  
        margin-top: 30px;
    }
    .darkbgcollectorrights .artistsrights h3{
        margin-bottom: 30px;
        font-size: 30px;
    }
    .darkbgcollectorrights .artistsrights {
        padding-bottom: 50px;
    }
    .darkbgcollectorrights .bottomtext {
        padding-bottom: 50px;
    }
}