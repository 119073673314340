.darkbgaboutus{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(57,57,57,1) 100%); 

    .innerbg{    
        position: relative;
        margin-top: 40px;
        overflow-x: hidden;
        padding-bottom: 60px;
    &::before{
        content: "";
        width: 620px;
        height: 400px;
        background-image: url(../../static/img/aboutbg1.svg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 30px;
        left: -250px;
    }   

    &::after{
        content: "";
        width: 500px;
        height: 370px;
        background-image: url(../../static/img/aboutbg2.svg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 10px;
        right: -30px;
    } 
    .actiondemotext{
        position: relative;
        
        .bottom-action{
            position: absolute;
            top: 32%;
            left: 50%;
            transform: translate(-50%, -70%);
            font-size: 18px !important;
            line-height: 24px;
            font-weight: 500;
            font-family: "Rubik";
        }
    }
    .left-side{
        display: flex;
        align-items: flex-end;
        margin-bottom: 40px;
    }
    .middle-side{
       .inviteform{
           h2{
               font-size: 30px;
               font-weight: bold;
               line-height: 36px;
               font-family: rubik;
               margin: 0 auto 40px;
               color: #fff;
               text-align: center;
           }
           p{
            font-size: 22px !important;
            font-weight: 400;
            line-height: 28px;
            font-family: rubik;
            margin: 0 auto;
            margin-bottom: 20px;
        }
       }
    }
    .right-side{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        padding-top: 5px;
        .inviteright{
            margin-top: 40px;
            margin-right: 50px;
        }
    }
    
}
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
    .darkbgaboutus .innerbg::before{
        width: 520px;
        height: 350px;
    }
    .darkbgaboutus .innerbg::after{
        width: 330px;
        height: 260px;
    }    
}
@media screen and (min-width: 800px) and (max-width: 900px){
    .darkbgaboutus .innerbg .middle-side .inviteform{padding: 0 55px;}
}
@media screen and (min-width: 700px) and (max-width: 800px){
    .darkbgaboutus .innerbg .middle-side .inviteform{padding: 0 30px;}
}
@media screen and (max-width: 900px) {
    .darkbgaboutus .innerbg::before,
    .darkbgaboutus .innerbg::after{
        display: none;
    }  
    .darkbgaboutus .innerbg .left-side{
        justify-content: center;
        margin-bottom: 50px;
    } 
    .darkbgaboutus .innerbg .right-side{
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 30px;
    } 
    .darkbgaboutus .innerbg{
        padding-bottom: 30px;
    }   
}
@media screen and (max-width: 767px) {
    .darkbgwhitelist .innerbg .center-container::before{
        display: none;
    }   
}