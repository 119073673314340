.darkbgopencall{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 20%, rgba(57,57,57,1) 100%); 

    .innerbg{
        margin-top: 100px;
        padding-bottom: 150px;
        .left-container{
            position: relative;

            &::before{
                content: "";
                width: 300px;
                height: 380px;
                background-image: url(../../static/img/whitelistbg.svg);
                background-position: left top;
                background-repeat: no-repeat;
                position: absolute;
                top: 0px;
                left: 0px; 
                z-index: 0;           
            } 
            .opencalldata{              
                padding: 100px 0px 0 220px;
                z-index: 9;
                position: relative;
                h2{
                    font-size: 30px;
                    line-height: 26px;
                    font-weight: 500;
                    font-family: rubik;
                    margin-bottom: 12px;                    
                    color: #fff;
                }
                p{
                    font-size: 20px !important;
                    line-height: 28px;
                    font-weight: 400;
                    font-family: rubik;
                    margin-bottom: 20px;
                    color: #fff;

                    span{color:red}
                }
                .signupbtn{
                    text-align: center;
                    margin-top: 60px;

                    a{
                        background-color: #681F9D;
                        text-transform: uppercase;
                        border-radius: 45px;
                        font-size: 14px;
                        padding: 14px 70px;
                        line-height: 14px;
                        font-weight: 500;
                        font-family: rubik;
                        border: none;
                        text-decoration: none;
                        color: #fff;

                        &:hover{
                            background-color: #491270;
                        }
                    }
                }
            }
            
        }
        .right-container{

            .rightimage{
                padding-left: 50px;

                img{width: 100%;}
            }
        }
    }
}

@media screen and (min-width:768px) and(max-width: 991px) {
    .right-container{margin-top: 50px !important;}
    .darkbgopencall .innerbg{padding-bottom: 100px;}
    .darkbgopencall .innerbg .left-container .opencalldata {
        padding: 100px 0px 0 100px;
    }
}
@media screen and (min-width:992px) and(max-width: 1200px) {
    .darkbgopencall .innerbg .left-container .opencalldata {
        padding: 70px 0px 0 120px;
    }
}
@media screen and (max-width: 767px) {
    .darkbgopencall .innerbg .left-container::before{display: none;}
    .darkbgopencall .innerbg .left-container .opencalldata{padding: 50px 0px;}
    .darkbgopencall .innerbg .right-container .rightimage{padding-left: 0;}
    .right-container{margin-top: 30px !important;}
    .darkbgopencall .innerbg{padding-bottom: 100px;}
    .darkbgopencall .innerbg .left-container .opencalldata .signupbtn{margin-top: 30px;}
}