.darkbgmainsign{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 28%, rgba(57,57,57,1) 100%); 

    .innerbox{
        padding-bottom: 240px;
        h2{
            font-size: 35px;
            font-weight: bold;
            line-height: 42px;
            margin-top: 50px;
            margin-bottom: 100px;
            color: #fff;
            text-align: center;
            font-family: 'rubik';
        }
    }

}
.mainsignbox{
    position: relative;
    text-align: center;
    a{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-8%, 40%);
        text-decoration: none;

        p{
            font-size: 26px !important;
            font-weight: 400;
            line-height: 32px;
            font-family: rubik;
            margin-bottom: 0;
            max-width: 200px;
            text-align: left;
        }
    }
}

@media screen and (max-width:900px){
    .mainsignbox {
        margin-bottom: 40px;
    }
    .darkbgmainsign .innerbox{
        padding-bottom: 150px;
    }
}