.darkgallerysign{
    background: rgb(0,0,0);   
    padding-bottom: 60px;  

    .uploadLoader{
        position: fixed;
        width: 100%;
        background-color: rgb(0, 0, 0 , 80%);
        height: 100vh;
        z-index: 999;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .uploadinner{
           text-align: center;
        }

        img{width: 100px; height: 100px;}
        p{
            font-size: 20px !important;
            line-height: 30px;
            font-weight: 500;
            font-family: 'Rubik';
            color: #fff;
            margin-top: 30px;
        }

    }
.Signup {
    margin-top: 100px !important;
    //padding-bottom: 80px !important;
    .formbg {
        padding: 0;
        width: 380px;
        margin: 0 auto;
        position: relative;

        &::before{
            content: "";
            width: 400px;
            height: 400px;
            background-image: url(../../static/img/gallrysignbg.svg);
            position: absolute;
            background-repeat: no-repeat;
            left: -75px;
            top: -100px;
            z-index: 0;
        }
    }
    .noformbgleft{
        padding: 0;
        width: 315px;
        margin-left: auto;
        position: relative;
        margin-right: -50px;
        z-index: 9;
    }
    .noformbgright{
        padding: 200px 0 0;
        width: 315px;
        margin-right: auto;
        position: relative;
        margin-left: -50px;
        z-index: 9;

        svg{margin-right: 8px;}
    }
    .formHead {
        font-size: 30px;
        line-height: 36px;
        color: #fff;
        font-family: 'Rubik';
        text-align: center;
        margin-bottom: 50px;
        font-weight: 500;
        z-index: 9;
    }
    input {
        border-radius: 8px;
        background: #F0F4F8;
        height: 60px;
        margin-bottom: 20px;
        font-size: 16px;
        color: #A6BCD0;
        letter-spacing: -0.16px;
        line-height: 22px;
        padding-left: 16px;
        font-family: Acumin Pro SemiCondensed
    }
    .uploadgroup{
        position: relative;
        background-color: #f0f4f8;
        margin-bottom: 15px;
        border-radius: 8px;
        p{
            border-radius: 8px;
            background: #F0F4F8;
            margin-bottom: 15px;
            font-size: 16px;
            color: #A6BCD0;
            letter-spacing: -0.16px;
            line-height: 22px;
            padding-left: 30px;
            padding-top: 20px;
            font-family: Acumin Pro SemiCondensed;
            min-height: 120px
        }
        .uploads{
            height: 130px;           
        }
        .uploadBtn{
            position: absolute;
            bottom: 25px;
            left: 125px;
            height: 40px;
            width: 130px !important;
            margin-top: 0;

            .upload-data{
                position: absolute;
                top: 0;
                left: 0;
                height: 40px;
                opacity: 0;
            }
        }
    }
    textarea{
        border-radius: 8px;
        background: #F0F4F8;
        height: 300px;
        margin-bottom: 15px;
        font-size: 16px;
        color: #A6BCD0;
        letter-spacing: -0.16px;
        line-height: 22px;
        padding: 20px 30px;
        font-family: Acumin Pro SemiCondensed
    }
    textarea::placeholder,
    input::placeholder{
        font-size: 16px;
        font-weight: 400;
        color: #A6BCD0;
        letter-spacing: -0.16px;
        line-height: 22px;
        font-family: Acumin Pro SemiCondensed
    }
    .btn-primary {
        width: 206px;
        height: 46px;
        background: #681f9d;
        border: none;
        font-size: 14px;
        line-height: 14px;
        font-family: 'Rubik';
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -0.14px;
        border-radius: 36px;
        margin-top: 20px;
        img{
            position: relative;
            right: 7px;
            width: 13px;
        }
    }
    .SignupBtn{
        margin-top: 10px;
    }
    .btn-primary:hover, .btn-primary:focus{
        background: #491270 ;
        box-shadow: none;
    }
    a {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 500;
        font-family: 'Rubik';
        color: #fff !important;
        text-decoration: none;
        width: auto;
        display: table;
        margin: 0 auto;
        margin-top: 20px;
    }
    span {
        position: absolute;
        padding-left: 20px;
        top: 15px;
    }
     div {
        position: relative;
    }
}
}
@media screen and (min-width: 1201px) and (max-width: 1400px){
    .darkgallerysign .Signup .noformbgright{margin-left: 0;}
    .darkgallerysign .Signup .noformbgleft{margin-right: 0;}
}
@media screen and (min-width: 901px) and (max-width: 1200px){
    .darkgallerysign .Signup .noformbgleft{
        margin-right: initial;
        width: 290px;
    }
    .darkgallerysign .Signup .formbg{width: 290px;}
    .darkgallerysign .Signup .noformbgright{
        margin-left: initial;
        width: 270px;
    }
    .darkgallerysign .Signup .formbg::before{
        left: -130px;
        top: -76px;
    }
    .darkgallerysign .Signup .formHead{
        margin-bottom: 30px;
    }
    .darkgallerysign .Signup .uploadgroup .uploadBtn{
        bottom: 15px;
        left: 79px;
    }
}
@media screen and (max-width: 900px){
    .Signup .formbg::before{display: none;}
    .darkgallerysign .Signup{
        display: block;
        padding-bottom: 50px;
    }
    .darkgallerysign .Signup .noformbgleft,
    .darkgallerysign .Signup .formbg,
    .darkgallerysign .Signup .noformbgright{
        width: initial;
        margin-left: initial;
    }
    .darkgallerysign .Signup .noformbgright{
        padding-top: 0;
    }
    .darkgallerysign .Signup .SignupBtn{
        margin-top: 20px;
        margin-left: 20px;
    }
    .darkgallerysign .Signup .uploadgroup .uploadBtn{left: 30px;}
}
@media screen and (max-width: 767px) {
    .darkgallerysign .Signup {
        margin-top: 50px!important;
        margin-bottom: 0px !important;
        padding: 0 15px 50px;
    }    
}
@media screen and (max-width: 576px) {
    .darkgallerysign .Signup {
        padding: 0 15px 50px;
    }    
}
@media screen and (max-width: 576px) {
    .darkgallerysign .Signup .SignupBtn{margin-left: 0;}
}