@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');


  @font-face {
    font-family: 'FONTSPRING DEMO - ArponaSans';
    src: url('./static/fonts/Arpona-Sans/FONTSPRINGDEMO-ArponaSansRegular.woff2') format('woff2'),
        url('./static/fonts/Arpona-Sans/FONTSPRINGDEMO-ArponaSansRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'FONTSPRING DEMO - ArponaSans';
    src: url('./static/fonts/Arpona-Sans/FONTSPRINGDEMO-ArponaSansMediumRegular.woff2') format('woff2'),
        url('./static/fonts/Arpona-Sans/FONTSPRINGDEMO-ArponaSansMediumRegular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'FONTSPRING DEMO - ArponaSans';
    src: url('./static/fonts/Arpona-Sans/FONTSPRINGDEMO-ArponaSansBlackRegular.woff2') format('woff2'),
        url('./static/fonts/Arpona-Sans/FONTSPRINGDEMO-ArponaSansBlackRegular.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./static/fonts/Helvetica/Helvetica.woff2') format('woff2'),
      url('./static/fonts/Helvetica/Helvetica.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('./static/fonts/Helvetica/Helvetica-Bold.woff2') format('woff2'),
      url('./static/fonts/Helvetica/Helvetica-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Acumin Pro'), url('./static/fonts/acumin-pro/Acumin-RPro.woff') format('woff');
  }

  @font-face {
    font-family: 'Acumin Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Acumin Pro Bold'), url('./static/fonts/acumin-pro/Acumin-BdPro.woff') format('woff');
    }

  @font-face {
    font-family: 'Muli';
    src: local('Muli'), url('./static/fonts/muli/muli.regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'AcherusMilitant1';
    src: url('./static/fonts/Acherus Militant 1/AcherusMilitant1-Light.woff2') format('woff2'),
        url('./static/fonts/Acherus Militant 1/AcherusMilitant1-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AcherusMilitant1';
    src: url('./static/fonts/Acherus Militant 1/AcherusMilitant1-Bold.woff2') format('woff2'),
        url('./static/fonts/Acherus Militant 1/AcherusMilitant1-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


/* iframe {
    display: none !important;
} */
body {
    color: #fff;
    font-family: open Sans;
    overflow-x: hidden;
}
iframe {
  display: none !important;
}
p {
    color: #fff;
}
.errorMessage{
  font-size: 16px !important; 
  font-weight: 500;
  color: #fff; 
  background: #bd4945; 
  padding: 10px; 
  text-align: center; 
  margin-top:20px !important;
  max-width: 400px;
  border-radius: 8px;
  margin: 0 auto;
}
.successMessage{
  font-size: 16px !important; 
  font-weight: 500; 
  color: #fff;
  background: #26a95e; 
  padding: 10px; 
  text-align: center; 
  margin-top:20px !important; 
  border-radius: 8px;
  max-width: 400px;
  border-radius: 8px;
  margin: 0 auto; 
}
.error{font-size: 12px !important;
  color: red;
  position: absolute;
  top: 60px;
}
.errcont{ top: 94px;}
.errconttext{top: initial; bottom: -34px;}
.errorTextarea{
  font-size: 12px !important;
  color: red;
  position: absolute;
  bottom: -34px;
}
.bodyhide{overflow: hidden;}