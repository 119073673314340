.darkbgwhitelist{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 30%, rgba(57,57,57,1) 100%); 

    .innerbg{    
        position: relative;
    &::before{
        content: "";
        width: 400px;
        height: 310px;
        background-image: url(../../static/img/whitelistbg1.svg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 30px;
        left: 0;
    }   

    &::after{
        content: "";
        width: 400px;
        height: 310px;
        background-image: url(../../static/img/whitelistbg2.svg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: -10px;
        right: 30px;
    } 

    .center-container{
        margin: 150px auto;
        position: relative;
        max-width: 700px;
        
        &::before{
            content: "";
            width: 300px;
            height: 380px;
            background-image: url(../../static/img/whitelistbg.svg);
            background-position: left top;
            background-repeat: no-repeat;
            position: absolute;
            top: -60px;
            left: 120px; 
            z-index: 0;           
        } 

        
        .inviteform{
            text-align: center;
            padding: 0 60px;
            z-index: 9;
            position: relative;
            padding-bottom: 240px;

            h2{
                font-size: 30px;
                font-weight: 500;
                line-height: 40px;
                font-family: rubik;
                margin-bottom: 30px;
                color: #fff;
            }
            p{
                font-size: 20px !important;
                font-weight: 500;
                line-height: 24px;
                font-family: rubik;
                margin-bottom: 40px;
                color: #fff;
            }
            .whitelistform{
                display: flex;
                align-items: center;
                justify-content: center;

                .form-control{
                    border-radius: 8px;
                    height: 38px;                    
                    &:hover, &:focus{
                        box-shadow: none;
                        outline: none;
                    }
                }
                button{
                    background: transparent;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 24px;
                    border: none;
                    margin-left: 16px;
                    &:hover, &:focus{
                        box-shadow: none;
                        outline: none;
                        color: #aeb0b1;
                    }
                }
            }
        }
    }
    
}
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
    .darkbgwhitelist .innerbg::before,
    .darkbgwhitelist .innerbg::after{
        width: 330px;
        height: 260px;
    }
    .darkbgwhitelist .innerbg .center-container .inviteform{
        padding: 0 0 0 60px;
        padding-bottom: 200px
    }
}
@media screen and (max-width: 900px) {
    .darkbgwhitelist .innerbg::before,
    .darkbgwhitelist .innerbg::after{
        display: none;
    }
    .darkbgwhitelist .innerbg .center-container .inviteform{
        padding-bottom: 150px
    }
}
@media screen and (max-width: 767px) {
    .darkbgwhitelist .innerbg .center-container::before{
        display: none;
    }
    .darkbgwhitelist .innerbg .center-container .inviteform{
        padding: 0;
    }
}