.darkbgusersign{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 10%, rgba(57,57,57,1) 100%); 

.Signup {
    margin-top: 100px !important;
    padding-bottom: 80px !important;
    .col-lg-5 {
        padding: 0;
        width: 315px;
        margin: 0 auto;
        position: relative;

        &::before{
            content: "";
            width: 400px;
            height: 400px;
            background-image: url(../../static/img/usersign.svg);
            position: absolute;
            background-repeat: no-repeat;
            left: -84px;
            top: -8px;
        }
    }
    .formHead {
        font-size: 30px;
        line-height: 36px;
        color: #fff;
        font-family: 'Rubik';
        text-align: center;
        margin-bottom: 50px;
        font-weight: 500;
    }
    input {
        border-radius: 8px;
        background: #F0F4F8;
        height: 60px;
        margin-bottom: 15px;
        font-size: 16px;
        color: #A6BCD0;
        letter-spacing: -0.16px;
        line-height: 22px;
        padding-left: 55px;
        font-family: Acumin Pro SemiCondensed
    }
    input::placeholder{
        font-size: 16px;
        color: #A6BCD0;
        letter-spacing: -0.16px;
        list-style: 22px;
        font-family: Acumin Pro SemiCondensed
    }
    .btn-primary {
        width: 100%;
        height: 60px;
        background: #681f9d;
        border: none;
        font-size: 14px;
        line-height: 17px;
        font-family: 'Rubik';
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -0.14px;
        border-radius: 36px;
        margin-top: 20px;
        img{
            position: relative;
            right: 7px;
            width: 13px;
        }
    }
    .SignupBtn{
        margin-top: 20px;
    }
    .btn-primary:hover{
        background: #491270 ;
    }
    a {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 500;
        font-family: 'Rubik';
        color: #fff !important;
        text-decoration: none;
        width: auto;
        display: table;
        margin: 0 auto;
        margin-top: 20px;
    }
    span {
        position: absolute;
        padding-left: 20px;
        top: 15px;
    }
     div {
        position: relative;
    }
}
}
@media screen and (min-width: 768px) and (max-width: 900px) {
    .darkbgusersign .Signup {
        padding-bottom: 190px !important;
    }
}
@media screen and (max-width: 767px) {
    .Signup {
        margin-top: 50px!important;
    }
    .Signup .col-lg-5::before{display: none;}
}
@media screen and (max-width: 400px) {
.Signup{
    .col-lg-5{
        width: 100%;
        padding: 0 15px;
    }
}
}