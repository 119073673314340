body {
    padding: 0;
    margin: 0;
   }
   p {
       font-size: 14px !important;
   }
   .darkbgcomponent{
    background-color: #050505 !important;
   }
   
   // Header CSS 
   
   .rowheadersection {
       color: #fff;
       a {
           color: #fff;           
       }
       .navbar-nav{
           align-items: baseline;
       }
       .nav-link {
        color: #fff !important;
        margin: 0px 34px;
        height: 50px;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        font-family: 'Rubik';
        line-height: 50px;        
          
           &:hover{
               text-decoration: none;
           }
       }
       nav.navbar.navbar-expand.navbar-light {
           margin: 10px 0px;
       }
       span.navbar-brand {
           color: #fff !important;
           font-size: 22px;
           line-height: 40px;
           text-transform: uppercase;
           margin-right: 0px;
           padding: 4px 0px;
           max-width: 170px;
           margin-right: 40px;
           line-height: 50px; 
           img{width: 100%;}
       }
       .btn_login {
            height: 40px;
            line-height: 40px;
            padding: 0px 20px;
            background-color: #681f9d;
            border: none;
            border-radius: 60px;
            font-size: 16px;
            font-family: 'Rubik';
            min-width: 110px;
            margin-left: 34px;
            text-transform: uppercase;
            font-weight: 600;
            white-space: nowrap;

            &:hover, &:focus{
                background-color: #491270;
                box-shadow: none;
                color: #fff;
            }
       }
   }
   
   // Footer Section
   
   .footersection {
       background-color: #353535;
       padding: 50px 0px;
       border-top: 1px solid #707070;
       //margin-top: 30px;
   }
   .footerleftside {
       display: flex;
       .logo_footer {
           width: 100px;
           color:#fff;
           font-family:Acumin Pro;
           font-weight: bold;
           font-size: 13px;
           margin-top: 5px;
           margin-right: 45px;    
           
           img{
               width: 100%;
           }
       }
       .footerlinks {
           ul {
               padding: 0px;
               list-style: none;
               min-width: 150px;
               margin-bottom: 0;
               li {
                   
                   a {
                       color: #fff;
                       text-decoration: none;
                       font-family: Helvetica;
                       font-size: 13px;
                       font-weight: bold;
                       line-height: 20px;

                   &:hover {
                       color: #aeb0b1;
                   }
                   }
                   
               }
           }
       }
       
   }
  
   
   .Emailfooterform {
       position: relative;
       margin-left: 50px;
       max-width: 350px;
       input {
           height: 40px;
           background-color: transparent;
           border: 1px solid #CBD0D3;
           color: #CBD0D3;
           font-size: 14px;
           padding: 0px 10px;

           &::placeholder{
            color: #CBD0D3;
           }
           &:focus{
               box-shadow: none;
               background-color: transparent;
               border: 1px solid #CBD0D3;
               color: #CBD0D3;
           }
       }
       button.iconsubmit {
           position: absolute;
           top: 0px;
           right: 0px;
           height: 40px;
           background-color: transparent;
           border: 0px none;
           color: rgba(255,255,255, 0.9);
       }
       form p {
           font-size: 11px !important;
           color: #CBD0D3;
           font-family: Helvetica;
       }
   }
   
   .socialicons {
       text-align: right;
        ul {
           padding: 0px;
           list-style: none;
           margin: 0;
           li {
               display: inline-block;
               padding: 0px 6px;
               margin-left: 12px;
               color: #fff;
               a {
                   color: #fff;
                   font-size: 16px !important;
                   text-decoration: none !important;

                   &:hover {
                    color: #aeb0b1;
                }
               }
           }
       }
   }
   
   

  
   .video-responsive {
    overflow: initial;
    padding-bottom: inherit;
    position: relative;
    height: auto;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 85vh;
    width: 100%;
    position: absolute;
  }
  .video-responsive iframe {
    display: block !important;
    position: relative;
  }

  .LogoResponsive {
      display: none;
  }
 .Headersearch.input-group{
    width: 170px;
    margin-right: 20px;
    position: relative;
    input.form-control {
        background-color: transparent;
        border: 1px solid #9d7070;
        height: 40px;
        border-radius: 25px !important;
        color: #fff;
    }
    button {
        background-color: transparent;
        border: 0px none;
        position: absolute;
        right: 7px;
        top: 5px;

        &:focus{
            border: none;
            box-shadow: none;
        }
    }
 }
// Responsinve CSS Start

@media screen and (max-width: 768px) {   
    .socialicons{text-align: center !important; margin-bottom: 10px;}
    .footersection{padding: 40px 0px 0px;}
    .mb-3{margin-bottom: 8px !important;}
    .Headersearch.input-group{display: none;}
  }

$small: 300px;
$medium: 991px;
@media screen and (min-width: 1100px) and (max-width: 1400px) {
    .rowheadersection .nav-link{
        margin: 0px 16px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1025px) {
    .rowheadersection .nav-link{
        margin: 0px 18px;
        font-size: 14px;
        line-height: 24px;
    }
    .footerleftside .logo_footer{
        margin-right: 25px;
        min-width: 70px;
    }
    .footerleftside .footerlinks ul{
        min-width: 120px;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .footersection .col-lg-3 {
        width: 50%;
    }
    .footersection .socialicons {
        text-align: center;
    }
    .Headersearch.input-group{display: none;}
    .Emailfooterform {
        margin-top: 30px;
        margin-left: 0;
    }
}

@media screen and (min-width: 300px) and (max-width: 449px){
    .footerleftside {
        display: block;
        text-align: center;
    }
    .footerleftside .logo_footer {
        margin-right: 0;
        img{width: initial;}
    }
    
    .footerleftside .footerlinks ul li{
       margin-bottom: 10px;
    }
    .Emailfooterform {
        margin-top: 20px;
        margin: 20px auto;
        max-width: 320px;
    }
    
}
@media screen and (min-width: 450px) and (max-width: 767px){
   
    .footerleftside .logo_footer{
        margin-right: 25px;
        width: 80px;

        img{
            width: 100%;
        }
    }
    .footerleftside .footerlinks{
        width: 22%;
        display: inline-block;
    }
    .footerleftside .footerlinks ul{
        min-width: 100px;
    }
    .Emailfooterform {
        margin-top: 20px;
    }
}
  @media screen and (min-width: $small) and (max-width: $medium) {
    .LogoResponsive {
        display: block;
    }
    .navbar-light .navbar-toggler {
        color: #fff;
        background-color: #fff;
        border-radius: 2px;
        padding: 5px 3px;        
    }
    .rowheadersection span.navbar-brand {
        padding: 4px 0px;
    }
    .LogoMain {
        display: none !important;
    }
    .rowheadersection .btn_login {
        margin: 10px 0 0;
    }
    
    div#basic-navbar-nav {
        background-color: rgb(52 54 60);
        padding: 20px 20px;
        position: fixed;
        width: 100%;
        z-index: 99;
        right: 0px;
        left: 0px;
        height: 100%;
        top: 80px;
        transition: all ease 0.5s;
    }
    div#basic-navbar-nav .navbar-nav a.nav-link {
        color: #fff !important;
        margin: 0px 0px !important;
        height: 50px;
        line-height: 50px;
        padding: 0px 10px !important;
        text-align: center;
        font-size: 22px !important;
    }
    .rowheadersection .btn-connectwallet {
        max-width: 170px;
        //margin: 30px auto 0px;
    }
    .footerleftside {       
        // .logo_footer {
        //     margin-bottom: 20px;
        //     text-align: center;
        // }
        // .footerlinks {
        //     width: 33.33%;
        //     display: inline-block;
        //     ul {
        //         min-width: auto;
        //     }
        // }
        .Emailfooterform {
            margin-top: 20px;
            margin-bottom: 30px;
        }
        .socialicons {
            ul {
                margin: 0px auto;
            }
        }
    }
  }
  
// Responsive CSS end 


//loader css
.lds-spinner{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
}
.darkbgBlack1{
    background-color: #24262b;
}

@media screen and (max-width: 1320px){
    .rowheadersection .nav-link  {
        margin: 0px 10px;
        font-size: 14px;
    }
    .rowheadersection .btn_login{
        font-size: 14px;
    }
}
@media screen and (min-width: 880px){
.navbar-expand-lg .navbar-collapse {
    justify-content: end;
}
}

//Thank you page css
.darkbgthankyou{
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(57, 57, 57, 1) 100%);
    .thank-container{
        padding: 0;
        background-image: url(../static/img/thnksimg.svg);
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-size: cover;
        position: relative;
        display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        .thankyoudata{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           h2{
               font-size: 80px;
               line-height: 87px;
               font-weight: bold;
               letter-spacing: -0.1px;
               font-family: Rubik;
               color: #fff;
           }
           h3{
            font-size: 60px;
            line-height: 77px;
            font-weight: bold;
            letter-spacing: -0.1px;
            font-family: Rubik;
            color: #fff;
           }
           a{
            width: 206px;
            height: 46px;
            background: #681f9d;
            border: none;
            font-size: 14px;
            line-height: 14px;
            font-family: "Rubik";
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: -0.14px;
            border-radius: 36px;
            margin-top: 50px;
            color: #fff;
            text-decoration: none;
            line-height: 46px;
            text-align: center;
           }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1100px)  {
    .darkbgthankyou .thank-container .thankyoudata h2{
        font-size: 50px;
        line-height: 67px;
    }
    .darkbgthankyou .thank-container .thankyoudata h3{
        font-size: 40px;
        line-height: 50px;
    }
    .darkbgthankyou .thank-container .thankyoudata{
        padding: 0 16px;
        text-align: center;
    }
}
@media screen and (min-width: 300px) and (max-width: 576px)  {
    .darkbgthankyou .thank-container .thankyoudata h2{
        font-size: 30px;
        line-height: 42px;
    }
    .darkbgthankyou .thank-container .thankyoudata h3{
        font-size: 24px;
        line-height: 28px;
    }
    .darkbgthankyou .thank-container .thankyoudata{
        padding: 0 16px;
        text-align: center;
    }
    .darkbgthankyou .thank-container .thankyoudata a{
        margin-top: 30px;
    }
}